.age-group-list {
  padding: 20px 60px;
}
.age-group-list .age-group-item {
  display: inline-block;
  width: 138px;
  height: 138px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(153, 134, 89, 0.4);
  border-radius: 20px;
  text-align: center;
  border: 6px solid transparent;
  cursor: pointer;
}
.age-group-list .age-group-item:nth-of-type(1),
.age-group-list .age-group-item:nth-of-type(3) {
  margin-right: 86px;
}
.age-group-list .age-group-item:nth-of-type(1),
.age-group-list .age-group-item:nth-of-type(2) {
  margin-bottom: 40px;
}
.age-group-list .age-group-item .img-age {
  margin-top: 24px;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
}
.age-group-list .age-group-item .p-age {
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  font-family: DFPFangYuanW7;
  color: #448816;
}
.age-group-list .age-group-item.age-selected {
  border-color: #80d894;
  position: relative;
}
.age-group-list .age-group-item.age-selected .selected-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url("../../assets/icon-select-right.png");
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -14px;
  right: -14px;
  z-index: 1;
}
.age-group-list .age-group-item:not(.age-selected):hover {
  border-color: #9ae5ab;
}
